import axios from 'axios';
import { ReCaptcha } from '../libs/reCaptcha';
import { API_URL, API_HOSTNAME, RECAPTCHA_ACTIONS } from '../constants';
import { Auth } from './auth';

let instance = null;

class RequestInstance {
  constructor() {
    if (!instance) {
      this.profile = null;
      this.isAlreadyFetchingAccessToken = false;

      instance = this;
    }

    return instance;
  }

  async getToken(phone, password) {
    if (!phone || !password) {
      throw new Error('Error login data!');
    }

    const functionBody = async (captchaToken) => {
      const fingerPrint = Auth.getFingerPrint();

      if (!fingerPrint) {
        throw new Error('Fingerprint not found!');
      }

      const headers = { 'Fingerprint': fingerPrint };
      const data = { phone, password, captchaToken };
      const response = await axios({
        method: 'post',
        data,
        url: `${API_URL}/auth/login`,
        headers
      });

      if (!response.data.success) {
        throw new Error(response.data.error);
      }

      return response.data.data;
    };

    return ReCaptcha.execute(RECAPTCHA_ACTIONS.login, functionBody);
  };

  async refreshTokens(currentRefreshToken, fingerPrint) {
    if (!currentRefreshToken || !fingerPrint) {
      return false;
    }

    if (this.isAlreadyFetchingAccessToken) {
      return false;
    }

    this.isAlreadyFetchingAccessToken = true;

    try {
      const headers = { 'Fingerprint': fingerPrint };

      const response = await axios({
        url: `${API_URL}/auth/refreshtoken`,
        method: 'post',
        data: {
          refreshToken: currentRefreshToken,
        },
        headers,
      });

      const { accessToken, refreshToken, id } = response.data.data;

      Auth.setAuthData({ accessToken, refreshToken, id });

      return true;
    } catch (error) {
      this.logout();

      return false;
    } finally {
      this.isAlreadyFetchingAccessToken = false;
    }
  }

  async call_auth(method, url, data = {}) {
    const headers = Auth.getHeaders();
    const fullUrl = `${API_HOSTNAME}${url}`;
    const axiosParams = {
      method,
      url: fullUrl,
    };

    if (data instanceof FormData) {
      headers['Content-Type'] = 'multipart/form-data';
    }

    if (method === 'get') {
      axiosParams.params = data;
    } else {
      axiosParams.data = data;
    }

    try {
      const request = await axios({
        ...axiosParams,
        headers,
      });

      return request;
    } catch (error) {
      console.error(error);

      throw error;
    }
  }
}

export const Request = new RequestInstance();
