import { ApiCall } from '../../api/api-call';

export const blockPublication = async (publicationId, additional = {}) => {
  const { notify } = additional;

  try {
    const response = await ApiCall.blockPublicationById(publicationId);

    console.log(response);
  } catch (error) {
    console.error(error);
  }
};

export const unblockPublication = async (publicationId, additional = {}) => {
  const { notify } = additional;

  try {
    const response = await ApiCall.unblockPublicationById(publicationId);

    console.log(response);
  } catch (error) {
    console.error(error);
  }
};