import axios from 'axios';
import { fetchUtils } from 'react-admin';
import { Auth } from '../api/auth';
import simpleRestProvider from 'ra-data-simple-rest';
import { API_URL } from '../constants';

export const authProvider = {
  login: (params) => Auth.login(params),
  logout: () => Auth.logout(),
  checkError: error => Auth.checkError(error),
  checkAuth: (params) => Auth.checkAuth(params),
  getPermissions: params => Auth.getPermissions(params),
};

export const dataProvider =  () => {
  const fetchJson = async (url, options = {}) => {
    /*const { code } = Auth.getAccessToken();

    if (!code) {
      console.error('AccessToken not found!');
      return;
    }

    options.headers = new Headers({
      'Authorization': `Bearer ${code}`
    });*/

    return fetchUtils.fetchJson(url, options);
  };

  return simpleRestProvider(API_URL, fetchJson);
};

export const dataProviderCustom = () => {
  return {
    getList: async (resource, params) => {
      try {
        const urlSearch = new URLSearchParams();

        urlSearch.append('filter', JSON.stringify(params.filter));
        urlSearch.append('pagination', JSON.stringify(params.pagination));
        urlSearch.append('sort', JSON.stringify(params.sort));

        const headers = Auth.getHeaders();
        const total = await axios({
          url: `${API_URL}/${resource}/count?${urlSearch.toString()}`,
          method: 'get',
          headers,
        });
        const response = await axios({
          url: `${API_URL}/${resource}/?${urlSearch.toString()}`,
          method: 'get',
          headers,
        });

        return { data: response.data.data, total: total.data.data.count };
      }
      catch (error) {
        return error.response ? error.response.data : error;
      }
    },
    getOne: async (resource, { id }) => {
      try {
        const headers = Auth.getHeaders();
        const response = await axios({
          url: `${API_URL}/${resource}/${id}`,
          method: 'get',
          headers,
        });

        return response.data;
      }
      catch (error) {
        return error.response ? error.response.data : error;
      }
    },
    create: async (resource, params) => {
      try {
        const headers = Auth.getHeaders();
        const response = await axios({
          url: `${API_URL}/${resource}`,
          method: 'post',
          data: params.data,
          headers,
        });

        return response.data;
      } catch (error) {
        return error.response ? error.response.data : error;
      }
    },
    update: async (resource, params) => {
      try {
        const headers = Auth.getHeaders();
        const response = await axios({
          url: `${API_URL}/${resource}/${params.id}`,
          method: 'put',
          data: params.data,
          headers,
        });

        return response.data;
      } catch (error) {
        return error.response ? error.response.data : error;
      }
    },
    delete: async (resource, params) => {
      try {
        const headers = Auth.getHeaders();
        const response = await axios({
          url: `${API_URL}/${resource}/${params.id}`,
          method: 'delete',
          headers,
        });

        return response.data;
      } catch (error) {
        return error.response ? error.response.data : error;
      }
    },
  }
};
