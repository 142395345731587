import React, { useState } from 'react';
import axios from 'axios';
import {
  useRecordContext,
  List as ComponentList,
  Edit as ComponentEdit,
  EditButton,
  Datagrid,
  TextField,
  DateField,
  SearchInput,
  TextInput,
  SimpleForm,
  Button, 
  useRefresh, 
  useNotify, 
} from 'react-admin';
import { blockPublication, unblockPublication } from '../modules/effects/publication';

const PostPanel = () => {
  const record = useRecordContext();

  return (
    <div dangerouslySetInnerHTML={{ __html: record.text }} />
  );
};

const PostFilter = [
  <SearchInput placeholder='ID' source="id" alwaysOn />,
  <SearchInput placeholder='User ID' source="UserId" alwaysOn />,
  <SearchInput placeholder='Search' source="q" alwaysOn />,
];

export const List = () => {
  return (
    <ComponentList filters={PostFilter}>
      <Datagrid expand={<PostPanel />} expandSingle>
        <TextField source='id' />
        <TextField source='name' sortable={false} />
        <TextField source='blocked' sortable={false} />
        <TextField source='status' sortable={false} />
        <TextField label={'User ID'} source='User.id' />
        <DateField showTime={true} source='createdAt' />
        <DateField showTime={true} source='updatedAt' />
        <EditButton />
      </Datagrid>
    </ComponentList>
  );
};

const ToggleBlockButton = ({ source }) => {
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const [isPending, setIsPending] = useState(false);

  const handleClick = async () => {
    setIsPending(true);

    if (record[source]) {
      await unblockPublication(record.id, { notify });
    } else {
      await blockPublication(record.id, { notify });
    }

    setIsPending(false);
    refresh();
  };

  return (
    <Button label={record[source] ? 'Разблокировать' : 'Заблокировать'} disabled={isPending} onClick={handleClick} />
  );
};

export const Edit = (props) => {
  return (
    <ComponentEdit title={'Edit publication'} {...props}>
      <SimpleForm>
        <TextInput disabled source='id' />
        <TextInput source='name' />
        <TextInput multiline fullWidth source='text' />
        <TextInput source='CityId' />
        <TextInput source='DistrictId' />
        <TextInput multiline fullWidth source='media' />
        <ToggleBlockButton source="blocked" />
      </SimpleForm>
    </ComponentEdit>
  )
};