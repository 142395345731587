import { Request } from './request';

export const ApiCall = {
  blockPublicationById(id) {
    const url = `/api/publications/${id}/block`;

    return Request.call_auth('post', url, {});
  },
  unblockPublicationById(id) {
    const url = `/api/publications/${id}/unblock`;

    return Request.call_auth('post', url, {});
  },
}